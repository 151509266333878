<template>
  <base-dialog
    type="icon"
    :width="230"
    title="Añadir Caja"
    @open="open"
    ref="dialog"
    basic_btn
    @save="$refs.form.validate()"
  >
    <Form @validate="submit" :cash_register="cash_register" ref="form"></Form>
  </base-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddCashRegister",
  data() {
    return {
      cash_register: {},
    };
  },
  components: {
    Form: () => import("@/components/expense/FormCashRegister"),
  },
  methods: {
    open() {
      this.cash_register = {};
    },
    ...mapActions("cash_register", ["addCashRegister"]),
    submit() {
      this.$refs.dialog.close();
      this.addCashRegister(this.cash_register).then(() => {
        this.$refs.dialog.close();
        this.$emit("update");
      });
    },
  },
};
</script>

<style>
</style>